<template>
  <div>
    <Preloader v-if="callingAPI" on-top />
    <ModuleForm :module="nativeCampaign">
      <template #form-title>
        {{ $t(`route.${$route.name}`) }}
        <span v-if="nativeCampaign.status">
          {{ ` - `}}
          {{ nativeCampaign.status.toUpperCase()}}
        </span>
      </template>
      <template #header-buttons>
        <ModuleFormButton
          v-if="isSaveButtonVisible"
          :title="$t('buttons.save')"
          :required-permissions="requiredPermissions.saveButton"
          @click="save"
        />
        <ModuleFormButton
          v-if="isDuplicateButtonVisible"
          :title="$t('buttons.duplicate')"
          :required-permissions="requiredPermissions.duplicateButton"
          @click="goToDuplicate"
        />
        <ModuleFormDeleteButton
          v-if="nativeCampaign.campaignId"
          :record-id="nativeCampaign.campaignId"
          :required-permissions="requiredPermissions.deleteButton"
          action-id-param="campaignId"
          store-name="nativeCampaignManagement"
          list-router-path="nativeCampaignManagement"
        />
        <ModuleFormButton
          icon="close"
          @click="$router.push('/nativeCampaignManagement')"
         />
      </template>
      <template #left-column>
        <Input
          v-model="nativeCampaign.mainAlternative.title"
          @blur="$v.nativeCampaign.mainAlternative.title.$touch()"
          :error="$v.nativeCampaign.mainAlternative.title.$error"
          id="nativeCampaign_title"
          :label="$t('donBox.nativeCampaign.title')"
          :disabled="disabled"
        />
        <Input
          v-model="nativeCampaign.mainAlternative.url"
          id="nativeCampaign_url"
          :label="$t('donBox.nativeCampaign.pageUrl')"
          :disabled="disabled"
        />
        <Input
          v-model="nativeCampaign.agency"
          @blur="$v.nativeCampaign.agency.$touch()"
          :error="$v.nativeCampaign.agency.$error"
          id="nativeCampaign_agency"
          :label="$t('donBox.nativeCampaign.agency')"
          :disabled="disabled"
        />
        <Input
          v-model="nativeCampaign.client"
          @blur="$v.nativeCampaign.client.$touch()"
          :error="$v.nativeCampaign.client.$error"
          id="nativeCampaign_client"
          :label="$t('donBox.nativeCampaign.client')"
          :disabled="disabled"
        />
        <div class="title-text">
          {{ $t('donBox.nativeCampaign.image') }}
        </div>
        <ModuleFormPhotoBox
          ref="mainAlternativePhotoBox"
          :image="mainImage"
          :disabled="disabled"
          :error="$v.nativeCampaign.mainAlternative.damImageId.$error"
          @set-media="setImage"
          @remove-media="removeImage"
        >
          <template #firstButton>
            <button
              v-if="damSearchDefaultFilter"
              class="btn btn-sm btn-green"
              @click="openImageFromArticle"
            >
              <i class="fas fa-images"></i>
              {{ $t('donBox.nativeCampaign.loadImageFromArticle') }}
            </button>
          </template>
        </ModuleFormPhotoBox>
        <div
          v-for="(alternative, index) in nativeCampaign.alternatives"
          :key="`alternative-${index}-${alternative.damImageId}`"
        >
          <div class="alternatives__heading">
            <div class="alternatives__heading__text">
              {{ $t('donBox.nativeCampaign.alternativeName', { name: alternative.name }) }}
            </div>
            <ModuleFormButton
              v-if="!disabled"
              :disabled="deleteAlternativeDisabled"
              icon="delete"
              @click="removeAlternative(index)"
            />
          </div>
          <Input
            v-model="alternative.title"
            @blur="$v.nativeCampaign.alternatives.$each[index].title.$touch()"
            :error="$v.nativeCampaign.alternatives.$each[index].title.$error"
            id="nativeCampaign_alternative_title"
            :label="$t('donBox.nativeCampaign.title')"
            :disabled="disabled"
          />
          <Input
            v-model="alternative.url"
            id="nativeCampaign_url"
            :label="$t('donBox.nativeCampaign.pageUrl')"
            :disabled="disabled"
          />
          <div class="title-text">
            {{ $t('donBox.nativeCampaign.image') }}
          </div>
          <ModuleFormPhotoBox
            :image="alternativesImages[index]"
            :disabled="disabled"
            :error="$v.nativeCampaign.alternatives.$each[index].damImageId.$error"
            @set-media="setImage($event, index)"
            @remove-media="removeImage(index)"
          />
        </div>
        <button
          v-if="!disabled"
          :class="{'alternatives__add-button': true, 'alternatives__add-button--disabled': addAlternativeDisabled }"
          :title="$t('donBox.nativeCampaign.addAlternative')"
          :disabled="addAlternativeDisabled"
          @click="addAlternative"
        >
          <PlusIcon class="alternatives__add-button__icon" />
          {{ $t('donBox.nativeCampaign.addAlternative') }}
        </button>
      </template>
      <template #right-column>
        <OutlinedCheckbox
          v-if="showPauseCheckbox"
          class="show-pause-checkbox"
          v-model="nativeCampaign.paused"
          id="nativeCampaign_paused"
          :label="$t('donBox.nativeCampaign.pause')"
        />
        <Input
          v-model="nativeCampaign.clicksLimit"
          @blur="$v.nativeCampaign.clicksLimit.$touch()"
          :error="$v.nativeCampaign.clicksLimit.$error"
          type="number"
          :min="0"
          id="nativeCampaign_clicksLimit"
          :label="$t('donBox.nativeCampaign.clicksLimit')"
          :placeholder="`0`"
          :disabled="disabled"
        />
        <Input
          v-model="nativeCampaign.clickPrice"
          @blur="$v.nativeCampaign.clickPrice.$touch()"
          :error="$v.nativeCampaign.clickPrice.$error"
          type="number"
          :min="0"
          :step="0.01"
          id="nativeCampaign_clickPrice"
          :label="$t('donBox.nativeCampaign.clickPrice')"
          :placeholder="`0`"
          :disabled="disabled"
        />
        <Datepicker
          v-model="nativeCampaign.publishedFrom"
          @blur="$v.nativeCampaign.publishedFrom.$touch()"
          :error="$v.nativeCampaign.publishedFrom.$error"
          id="nativeCampaign_publishedFrom"
          :label="$t('donBox.nativeCampaign.publishedFrom')"
          :disabled="disabled"
          type="date"
        />
        <Datepicker
          v-model="nativeCampaign.publishedTo"
          @blur="$v.nativeCampaign.publishedTo.$touch()"
          :error="$v.nativeCampaign.publishedTo.$error"
          id="nativeCampaign_publishedTo"
          :label="$t('donBox.nativeCampaign.publishedTo')"
          :disabled="disabled"
          type="date"
        />
        <Input
          v-model="nativeCampaign.salesman"
          id="nativeCampaign_salesman"
          :label="$t('donBox.nativeCampaign.salesman')"
          :disabled="disabled"
        />
        <Input
          v-model="nativeCampaign.note"
          id="nativeCampaign_note"
          :label="$t('donBox.nativeCampaign.note')"
          :disabled="disabled"
        />
        <Input
          v-model="nativeCampaign.orderId"
          id="nativeCampaign_orderId"
          :label="$t('donBox.nativeCampaign.orderId')"
          :disabled="disabled"
        />
        <Select
          id="category"
          :label="$t('donBox.nativeCampaign.category')"
          @blur="$v.nativeCampaign.category.$touch()"
          :error="$v.nativeCampaign.category.$error"
          v-model="nativeCampaign.category"
          :options="categories"
          :disabled="disabled"
        />
        <div class="form-group">
          <div class="title-text">
            {{ $t('donBox.nativeCampaign.brands') }}
          </div>
          <MultiSelect
            v-model="nativeCampaign.brands"
            :error="$v.nativeCampaign.brands.$error"
            :options="sites"
            label="title"
            track-by="name"
            id="nativeCampaign_brands"
          />
        </div>
        <OutlinedCheckbox
          v-model="nativeCampaign.rrk"
          id="nativeCampaign_rrk"
          :label="$t('donBox.nativeCampaign.dividedByDays')"
          :disabled="disabled"
        />
        <OutlinedCheckbox
          :value="!nativeCampaign.isFree"
          @input="(value) => nativeCampaign.isFree = !value"
          id="nativeCampaign_paidCampaign"
          :label="$t('donBox.nativeCampaign.paidCampaign')"
          :disabled="disabled"
        />
      </template>
    </ModuleForm>
  </div>
</template>

<script>
import { maxLength, minLength, minValue, required } from 'vuelidate/lib/validators'
import { mapGetters, mapState } from 'vuex'
import Input from '@/components/form/inputs/Input'
import NativeCampaignModel from '@/model/NativeCampaignModel'
import NotifyService from '@/services/NotifyService'
import ModuleForm from '@/components/shared/ModuleForm'
import OutlinedCheckbox from '@/components/form/OutlinedCheckbox'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import ModuleFormDeleteButton from '@/components/shared/ModuleFormDeleteButton'
import ModuleFormPhotoBox from '@/components/shared/ModuleFormPhotoBox'
import Datepicker from '@/components/form/Datepicker'
import Select from '@/components/form/select/Select'
import PlusIcon from '@/assets/img/svg/plus.svg?inline'
import UserService from '@/services/user/UserService'
import { CATEGORIES_LIST } from '@/model/ValueObject/NativePerfCategories'
import Preloader from '@/components/preloader/Preloader'
import moment from 'moment'
import MultiSelect from '@/components/form/select/MultiSelect'
import PermissionService from '@/services/PermissionService'

const MAX_ALTERNATIVES_COUNT = 4

export default {
  name: 'NativeCampaignManagementNewView',
  props: {
    viewOnly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ModuleFormPhotoBox,
    ModuleFormDeleteButton,
    ModuleFormButton,
    Input,
    Select,
    ModuleForm,
    OutlinedCheckbox,
    Datepicker,
    PlusIcon,
    Preloader,
    MultiSelect
  },
  data () {
    return {
      nativeCampaign: this._.cloneDeep(NativeCampaignModel),
      mainImage: null,
      damSearchDefaultFilter: null,
      alternativesImages: [],
      numberOfPreviouslyExistingAlternatives: 0, // set this on loading an existing campaign
      categories: CATEGORIES_LIST,
      allBrandsOption: { name: 'ALL-NMH', title: this.$t('donBox.nativeCampaign.allNMHBrandOption') },
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.NATIVE_CAMPAIGN_MANAGEMENT_PERMISSIONS
    }
  },
  validations: {
    nativeCampaign: {
      mainAlternative: {
        title: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(250)
        },
        damImageId: {
          required
        }
      },
      agency: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(250)
      },
      client: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(250)
      },
      clicksLimit: {
        required,
        minValue: minValue(1)
      },
      clickPrice: {
        required,
        minValue: minValue(0)
      },
      publishedFrom: {
        required
      },
      publishedTo: {
        required
      },
      category: {
        required
      },
      brands: {
        required
      },
      alternatives: {
        $each: {
          title: {
            required,
            minLength: minLength(3),
            maxLength: maxLength(250)
          },
          damImageId: {
            required
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(['vlm']),
    ...mapState([
      'callingAPI'
    ]),
    sites () {
      return [this.allBrandsOption, ...this.$store.getters['site/allEnabledSorted']()]
    },
    creatingDuplicate () {
      return this.$router.currentRoute.path.includes('duplicate')
    },
    isSaveButtonVisible () {
      return !this.isDone || this.creatingDuplicate
    },
    isDuplicateButtonVisible () {
      return this.isDone && !this.nativeCampaign.duplicate && !this.creatingDuplicate
    },
    nativeCampaignImage () {
      return this.$store.getters['nativeCampaignManagement/nativeCampaignImage']
    },
    status () {
      return this.nativeCampaign.status?.toLowerCase()
    },
    isDone () {
      return this.status === 'done'
    },
    disabled () {
      return !this.creatingDuplicate && (this.isDone || this.viewOnly)
    },
    addAlternativeDisabled () {
      return !this.creatingDuplicate &&
        (this.nativeCampaign.alternatives.length >= MAX_ALTERNATIVES_COUNT ||
        ['done', 'active', 'paused'].includes(this.status) ||
        (this.status === 'inactive' && this.numberOfPreviouslyExistingAlternatives > 0))
    },
    deleteAlternativeDisabled () {
      const status = this.nativeCampaign.status?.toLowerCase()
      return !this.creatingDuplicate &&
        ['done', 'active', 'paused'].includes(status)
    },
    showPauseCheckbox () {
      return this.nativeCampaign.campaignId && ['active', 'paused'].includes(this.status)
    }
  },
  methods: {
    loadBrandsForDetail () {
      if (this.nativeCampaign.brands?.length > 0) {
        this.nativeCampaign.brands = this.$store.getters['site/mapNamesToSites'](this.nativeCampaign.brands)
      } else {
        this.nativeCampaign.brands = [this.allBrandsOption]
      }
    },
    async save () {
      let isValid = true
      if (!this.notInPast(this.nativeCampaign.publishedFrom) || !this.notInPast(this.nativeCampaign.publishedTo)) {
        NotifyService.setErrorMessage(this.$t('donBox.nativeCampaign.publishedIsInPast'))
        isValid = false
      }
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        isValid = false
      }
      if (!isValid) {
        return
      }
      const currentUser = this.$store.getters['user/currentUser']
      this.nativeCampaign.user = {
        id: currentUser.id.toString(),
        name: UserService.getUserInfo(currentUser)
      }
      if (this.nativeCampaign.brands.some(brand => brand.name === this.allBrandsOption.name)) {
        this.nativeCampaign.brands = [] // this means all brands
      } else {
        this.nativeCampaign.brands = this.nativeCampaign.brands.map(brand => brand.name)
      }
      const action = this.nativeCampaign.campaignId ? 'update' : 'create'
      const successNotify = this.nativeCampaign.campaignId ? 'record_was_updated' : 'record_was_created'
      this.$store.dispatch(`nativeCampaignManagement/${action}`, this.nativeCampaign)
        .then(campaignId => {
          if (!this.$store.getters['nativeCampaignManagement/error']) {
            NotifyService.setSuccessMessage(this.$t(`notify.${successNotify}`))
            if (action === 'create') {
              this.goToEdit(campaignId)
            } else {
              this.nativeCampaign = this.$store.getters['nativeCampaignManagement/detail'].data
              this.loadBrandsForDetail()
              if (this.status === 'paused') {
                this.nativeCampaign.paused = true
              }
            }
          } else {
            NotifyService.setErrorMessage(this.$store.getters['nativeCampaignManagement/error'])
            this.loadBrandsForDetail()
          }
        })
        .catch(error => console.error(error))
    },
    setImageToAlternative ({ image, imageId, filename, alternativeIndex }) {
      if (alternativeIndex || alternativeIndex === 0) {
        this.alternativesImages[alternativeIndex] = image
        this.nativeCampaign.alternatives[alternativeIndex].damImageId = imageId
        this.nativeCampaign.alternatives[alternativeIndex].damImageFileName = filename
      } else {
        this.mainImage = image
        this.nativeCampaign.mainAlternative.damImageId = imageId
        this.nativeCampaign.mainAlternative.damImageFileName = filename
      }
    },
    setImage (medias, alternativeIndex) {
      const image = medias[0]
      const { filename } = image.damMediaEmbed
      this.setImageToAlternative({ image, imageId: image.id.toString(), filename, alternativeIndex })
    },
    removeImage (alternativeIndex) {
      this.setImageToAlternative({ image: null, imageId: null, filename: null, alternativeIndex })
    },
    getNextAlternativeName (alternativeIndex) {
      return String.fromCharCode('B'.charCodeAt(0) + alternativeIndex)
    },
    addAlternative () {
      if (!this.addAlternativeDisabled) {
        this.nativeCampaign.alternatives.push({
          name: this.getNextAlternativeName(this.nativeCampaign.alternatives.length),
          url: null,
          title: null,
          damImageId: null,
          damImageFileName: null
        })
      }
    },
    removeAlternative (index) {
      this.alternativesImages.splice(index, 1)
      this.nativeCampaign.alternatives.splice(index, 1)
      this.nativeCampaign.alternatives.forEach((alternative, i) => {
        alternative.name = this.getNextAlternativeName(i)
      })
    },
    goToEdit (campaignId) {
      this.$router.push(`/nativeCampaignManagement/${campaignId}/edit`)
    },
    goToDuplicate () {
      this.$router.push(`/nativeCampaignManagement/${this.nativeCampaign.campaignId}/duplicate`)
    },
    notInPast (value) {
      return (!this.nativeCampaign.id || this.creatingDuplicate) &&
        (!value || !moment().startOf('day').isAfter(value))
    },
    openImageFromArticle () {
      this.$refs.mainAlternativePhotoBox.showDamSearchModal(this.damSearchDefaultFilter)
    }
  }
}
</script>
<style scoped lang="scss">
.title-text {
  color: #8A96AC;
  font-size: rem(14px);
}
.show-pause-checkbox {
  margin-top: rem(24px);
  margin-bottom: rem(20px);
}
.alternatives {
  &__heading {
    display: flex;
    margin-top: rem(20px);
    &__text {
      flex: 0 0 calc(100% - 25px);
      @include font(500 16px "Roboto");
      color: #465674;
      margin-bottom: rem(10px);
    }
  }
  &__add-button {
    @include font(700 13px "Roboto");
    @include padding(7px 14px);
    @include radius(4px);
    margin-top: 1rem;
    height: rem(30px);
    cursor: pointer;
    background: #6599fe;
    color: #fff;
    border: none;
    transition: background 200ms;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    @include bp(10) {
      &:hover {
        background: darken(#6599fe, 10%);
      }
    }
    &--disabled {
      background-color: #D1DBE4;
      cursor: default;
      &:hover {
        background-color: #D1DBE4;
      }
    }
    &__icon {
      @include size(rem(14px));
      fill: #FFFFFF;
      float: left;
      margin-right: rem(8px);
    }
  }
}

</style>
